import React from "react";
// import PropTypes from 'prop-types';

// class Button extends React.Component {
//     constructor(props) {
//         super(props);
//     }


//     render() {
//         return (
//             <div className="sm:flex sm:justify-center">
//                 <div className="rounded-md shadow">
//                     <a
//                         href={ this.props.link } 
//                         target={ this.props.newWindow ? "_blank" : null }
//                         className="w-full flex items-center justify-center border border-transparent text-base font-sm rounded-md text-white bg-wblue-dark hover:bg-wblue md:px-4"
//                     >
//                         {this.props.title}
//                     </a>
//                 </div>
//             </div>
//         );
//     }

// }

class CookieBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hidden: false };
    }

    toggle = () => {
        this.setState({
            hidden: !this.state.hidden,
        });
    }

    render() {
        return (
            <div className={this.state.hidden ? "hidden" : "min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12"}>
  
                <div className="max-w-screen-lg mx-auto fixed bg-white inset-x-5 p-5 bottom-40 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between">
                    <div className="w-full">This website uses cookies to ensure you get the best experience on our website. 
                        <a href="https://winterface.io/docs/security/privacy" className="text-wblue-dark whitespace-nowrap  hover:underline">&nbsp;Learn More</a>
                    </div>
                    <div className="flex gap-4 items-center flex-shrink-0" onClick={() => {this.toggle()}}>
                        <button class="bg-wblue-dark px-5 py-2 text-white rounded-md hover:bg-wblue focus:outline-none">I Understand</button>
                    </div>
                </div>
            
            </div>
        );
    }
}

export default CookieBanner;

CookieBanner.propTypes = {

};