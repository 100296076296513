import React from "react";
// import PropTypes from 'prop-types';

// custom components
import AccordionItem from "./AccordionItem";

// videos
import createvideo from "../assets/create.mp4";
// import sharevideo from "../assets/share.mp4";
import editvideo from "../assets/edit.mp4";
import trackvideo from "../assets/track.mp4";
import uploadvideo from "../assets/upload.mp4";

class Accordion extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
            selected: 0
        }
  }

  toggleOption = (item) => {
    if (item === this.state.selected) {
        this.setState({selected: -1})
    } else {
        this.setState({selected: item})
    }
  }

  render() {
    let {selected} = this.state;
    return (
        <div className=""> {/* bg-gray-100 */}
            <div className="w-full mx-auto bg-gray-900">
                <div className="pt-6 text-center text-3xl tracking-tight font-extrabold text-gray-200 sm:text-5xl md:text-6xl">
                    Features
                </div>
                <div className="pb-8 px-4 text-center text-gray-200 text-lg sm:text-xl md:text-2xl">
                    A quick look at what Winterface can do.
                </div>
            </div>
            <div className="max-w-4xl mx-auto">
                <section className="shadow">
                    {/* NEW TABLES */}
                    <AccordionItem 
                        adverb="Quickly" 
                        verb="create" 
                        tail="new tables" 
                        desc="Create new tables in Snowflake in seconds from scratch or by uploading a file." 
                        expanded={selected === 0}
                        onClick={()=>{this.toggleOption(0)}}
                    >
                        <video autoPlay muted loop playsInline preload="auto" width="100%" className="max-h-96 ">
                            <source src={createvideo} type="video/mp4" />
                        </video>
                    </AccordionItem>

                    {/* EDIT DATA */}
                    <AccordionItem
                        adverb="Simply" 
                        verb="edit" 
                        tail="existing data" 
                        desc="Edit your Snowflake data as simply as in a spreadsheet." 
                        desc2="No scripts needed."
                        expanded={selected === 1}
                        onClick={()=>{this.toggleOption(1)}}
                    > 
                        <video autoPlay muted loop playsInline preload="auto" width="100%" className="max-h-96 ">
                            <source src={editvideo} type="video/mp4" />
                        </video>
                    </AccordionItem>
                    
                    {/* UPLOAD FILES */}
                    <AccordionItem
                        adverb="Finally" 
                        verb="upload" 
                        tail="offline files" 
                        desc="Uploading files is finally simple. "
                        desc2="Winterface will merge, replace, or append your data automatically." 
                        expanded={selected === 2}
                        onClick={()=>{this.toggleOption(2)}}
                    > 
                        <video autoPlay muted loop playsInline preload="auto" width="100%" className="max-h-96 ">
                            <source src={uploadvideo} type="video/mp4" />
                        </video>
                    </AccordionItem>

                    {/* TRACK CHANGES */}
                    {/* <AccordionItem
                        adverb="Confidently" 
                        verb="track" 
                        tail="changes" 
                        desc="All changes are logged and can be reverted with a click of a button." 
                        expanded={selected === 3}
                        onClick={()=>{this.toggleOption(3)}}
                    > 
                        <video autoPlay muted loop playsInline preload="auto" width="100%" className="max-h-96 ">
                            <source src={trackvideo} type="video/mp4" />
                        </video>
                    </AccordionItem> */}
                    
                </section>
                
            </div>
        </div>
    );
  }
}

export default Accordion;


// Accordion.propTypes = {
//    adverb: PropTypes.string.isRequired,
//    verb: PropTypes.string.isRequired,
//    tail: PropTypes.string.isRequired,
//    desc: PropTypes.string.isRequired,
//    desc2: PropTypes.string,
// };