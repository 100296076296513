import React from "react";
// import PropTypes from 'prop-types';
import fulllogo from '../assets/fulllogo.svg'
import NavBarMobile from "./NavbarMobile";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hidden: true};
  }

  toggleMenu = () => {
    this.setState({
        hidden: !this.state.hidden,
    });
  }

  render() {
    return (
        <div className="w-full flex items-stretch">
        <div className="fixed bg-white pt-6 px-4 w-full mt-12 z-50 pb-4 sm:px-6 lg:px-8 xl:left-1/2 xl:transform xl:-translate-x-1/2 ">
       {/* <div className="relative pt-6 pb-2 px-4 z-10 h-300 bg-white sm:px-6 lg:px-8"> */}
        <NavBarMobile hidden={this.state.hidden} toggleMenu={this.toggleMenu} />
        <nav
          className="relative flex items-center justify-between sm:h-10 lg:justify-start "
          aria-label="Global"
        >
          <div className="flex items-center flex-grow flex-shrink-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <span className="sr-only">winterface.io</span>
                <img
                  className="h-8 w-auto sm:h-8 sm:mb-1"
                  alt="winterface.io"
                  src={fulllogo}
                />
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <button
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  id="main-menu"
                  aria-haspopup="true"
                  onClick={this.toggleMenu}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:ml-8 md:pr-4 md:space-x-8">
            <a
              href="/"
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              Home
            </a>

            {/* <a
              href="/blog"
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              Blog
            </a>

            <a
              href="/pricing"
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              Pricing
            </a> */}

            <a
              href="/docs"
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              Docs
            </a>

            <a
              href="https://share.hsforms.com/1BP2Hla-eQzCT0XbsCK-fTg5m24a"  
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              Contact
            </a>

            <a
              href="https://app.winterface.io"  
              className="font-medium text-blue-500 hover:text-blue-900"
            >
              Login
            </a>
            
            {/* <a href="/login" 
                className= "w-auto px-3 py-3 border border-transparent shadow text-base font-medium rounded-full text-white  bg-wblue hover:bg-wblue-dark md:py-3 md:text-medium md:px-3 md:w-24 md:h-8">
                Log in
            </a> */}

          </div>
        </nav>
        
      </div>
      </div>
    );
  }
}

export default NavBar;

NavBar.propTypes = {
	
};