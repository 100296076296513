import React from "react";
import archwin from '../assets/archetypewinterface.png'
// import PropTypes from 'prop-types';

// class Button extends React.Component {
//     constructor(props) {
//         super(props);
//     }


//     render() {
//         return (
//             <div className="sm:flex sm:justify-center">
//                 <div className="rounded-md shadow">
//                     <a
//                         href={ this.props.link } 
//                         target={ this.props.newWindow ? "_blank" : null }
//                         className="w-full flex items-center justify-center border border-transparent text-base font-sm rounded-md text-white bg-wblue-dark hover:bg-wblue md:px-4"
//                     >
//                         {this.props.title}
//                     </a>
//                 </div>
//             </div>
//         );
//     }

// }

class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hidden: true };
    }

    toggleMenu = () => {
        this.setState({
            hidden: !this.state.hidden,
        });
    }

    render() {
        return (
            <div className="w-full items-center">
                <div className="flex bg-wblue-light hover:bg-wblue fixed w-full z-20 px-10 py-3">
                    <div className="flex-0 pr-3 sm:px-5">
                        <a href="/">
                            <span className="sr-only">Archetype and Winterface</span>
                            <img
                                className="h-6"
                                alt="Archetype and Winterface"
                                src={archwin}
                            />
                        </a>
                    </div>
                    <div className="flex-0 text-xs pt-1 sm:text-base  sm:pt-0">
                        <p className="">
                            Winterface is now a part of <a href="https://archetypeconsulting.com" target="_blank" className="text-wblue-dark">Archetype</a>!
                        </p>
                        {/* <div className="flex gap-2 max-md:flex-wrap">
                            <Button title="Learn More" link="https://archetypeconsulting.com" />
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;

Banner.propTypes = {

};